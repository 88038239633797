/* table #dns, */
tr #dns,
td #dns {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td #dns,
tr #dns {
  max-width: 200px;
}

ol > li::marker {
  font-weight: bold;
}
