.container {
  width: 100%;
  height: 100%;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
  border: 0.5px solid #000;
  box-sizing: border-box;
  border-radius: 15px;
}
.playwrite-mx-style {
  font-family: 'Playwrite MX', cursive;
}
.dancing-script-style {
  font-family: 'Dancing Script', cursive;
}
.playwrite-ca-style {
  font-family: 'Playwrite CA', cursive;
}
.satisfy-style {
  font-family: 'Satisfy', cursive;
}
.great-vibes-style {
  font-family: 'Great Vibes', cursive;
}
