/* Container for the suggestions list */
.ideal-postcodes-autocomplete-dropdown {
  width: 100%;
  max-width: 600px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #fff;
  z-index: 9999;
  overflow: auto;
  max-height: 300px;
  border-radius: 30px;
}

/* Style for the suggestions in the list */
.ideal-postcodes-autocomplete-dropdown li {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}

/* Style for the hovered/selected suggestion */
.ideal-postcodes-autocomplete-dropdown li:hover,
.ideal-postcodes-autocomplete-dropdown li:focus {
  background-color: #f5f5f5;
}
