/* Style the main editor container */
.ck-editor__editable {
  font-family: inherit;
  font-size: 14px;
  border-radius: 10px;
  line-height: 1.4;
  overflow: hidden;
  padding: 10px;
}

.ck-content {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.ck-content h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.ck-content h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.ck-content ul,
ol {
  margin: 1em 0;
  padding-left: 0.5em;
}

.ck-content li {
  margin: 0.5em 0;
}

.ck-content p {
  margin-bottom: 10px;
}
